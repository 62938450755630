<template>
  <v-main v-cloak class="background">
    <v-container fill-height fluid>
      <v-row justify="center">
        <v-col cols="12">
          <v-card class="mx-auto" max-width="350">
            <v-card-title class="px-5">Login</v-card-title>
            <v-card-text class="pa-5 pt-2">
              <v-form ref="form">
                <v-text-field
                  v-model="email"
                  dense
                  outlined
                  append-icon="mdi-account-outline"
                  label="Email"
                  :rules="[(v) => !!v || '']"
                  @keyup="hasError = false"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  dense
                  outlined
                  append-icon="mdi-lock-outline"
                  label="Password"
                  type="password"
                  :rules="[(v) => !!v || '']"
                  @keyup="hasError = false"
                  @keyup.enter="login"
                ></v-text-field>
                <v-expand-transition>
                  <v-alert
                    v-if="hasError"
                    dense
                    outlined
                    prominent
                    class="subtitle-2"
                    type="error"
                  >
                    {{ errorMessage }}
                  </v-alert>
                </v-expand-transition>
                <v-btn
                  block
                  depressed
                  color="primary"
                  :loading="loading"
                  @click="login()"
                >
                  Login
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'Login',

  data: () => ({
    email: null,
    errorMessage: 'There was an error!',
    hasError: false,
    loading: false,
    password: null,
  }),

  methods: {
    async login() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;
        await this.$axios.post('auth/login', {
          email: this.email,
          password: this.password,
        });
        await this.$store.dispatch('getAuthenticatedUser');
        this.$router.replace({ name: 'dashboard' });
      } catch (error) {
        if (error && error.response) {
          this.errorMessage =
            error.response.status === 401 || error.response.status === 403
              ? error.response.data.error
              : 'There was an error!';
        }
        this.hasError = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.background {
  background: linear-gradient();
}
</style>
